import { Component, OnInit, Injector, Inject } from '@angular/core';
import { SecondPageEditBase, UserService, SvCorePublicService, SvUser } from 'sv-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends SecondPageEditBase implements OnInit {
    model: ModelDangNhap = new ModelDangNhap();
    showLogin = true;
    returnUrl: string;
    currUser: SvUser;


    constructor(
        protected _userService: UserService,
        protected _injector: Injector,
        private _router: ActivatedRoute,
        private _oauthService: OAuthService,
        protected _svCorePublicService: SvCorePublicService,
        protected router: Router,
    ) {
        super(_userService, _injector);
    }

    ngOnInit() {
        this.formGroup = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });

        this.returnUrl = this._router.queryParams['returnUrl'] || '/';
        this._oauthService.requireHttps = false;
        if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
            top.location.href = this.returnUrl;

        } else {
            localStorage.clear();
            sessionStorage.clear();
        }
    }

    doLogin() {
        this.submitting = true;

        if (this.formGroup.invalid) {
            this._notifierService.showError('Bạn nhập thiếu thông tin');
            return;
        }
        if (this.model.username !== undefined && this.model.password !== undefined) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then(result => {
                        this._oauthService.setupAutomaticSilentRefresh();
                        // this.authorizeService.savePermission(result);
                        this._notifierService.showSuccess(this._translateService.instant('LOGIN_OK'));
                        // this.app.getMenu();

                        var svUser = this._authenticationService.getCurrentUser();
                        if (svUser.typeId == 1 || svUser.typeId == 4) {
                            this.returnUrl = '/study';
                            setTimeout(() => {
                                this.submitting = false;
                                top.location.href = this.returnUrl;
                            }, 500);
                        }
                        else {
                            this._authenticationService.logout();
                            this._notifierService.showSuccess('Chỉ có tài khoản cán bộ / giáo viên mới được quyền truy cập!');
                        }


                    },
                        error => {

                            this._notifierService.showWarning(error.error.error_description)
                            setTimeout(() => {
                                this.submitting = false;

                            }, 500);
                        }
                    );
                }
            });

        } else {
            this._notifierService.showError('Bạn nhập thiếu thông tin');
        }
    }
    doResetPassword() {
        this.submitting = true;
        if (this.model.email !== undefined) {
            this._svCorePublicService.resetPassword(this.model).then(rs => {
                if (rs.success) {
                    this._notifierService.showSuccess('Mật khẩu mới đã được gửi vào email của bạn');
                    this.model = new ModelDangNhap();
                    // top.location.href = "/";
                } else {
                    this._notifierService.showWarning('Thông tin không hợp lệ');
                }
                this.submitting = false;
            }, error => {
                this._notifierService.showWarning(error.error.error);
                this.submitting = false;
            });
        }
        else {
            this._notifierService.showError('Bạn nhập thiếu thông tin');
        }
    }
}


class ModelDangNhap {
    username: string;
    password: string;
    remember: boolean;
    email: string;
}
