<nav class="navigation post-pagination xs-text-center">
    <div class="nav-links">
        <a *ngIf="pageIndex >=2" class="prev page-numbers" (click)="onPrev()"><i
                class="fa fa-angle-double-left"></i></a>
        <a *ngIf="pageIndex <= 1" class="prev page-numbers"><i class="fa fa-angle-double-left"></i></a>


        <a class="page-numbers" (click)="onPage(pageNum)" *ngFor="let pageNum of getPages()"
            [class.current]="pageIndex === pageNum">
            {{pageNum}}</a>


        <a *ngIf="lastPage()===false" class="next page-numbers" (click)="onNext()"><i
                class="fa fa-angle-double-right"></i></a>
        <a *ngIf="lastPage()" class="next page-numbers"><i class="fa fa-angle-double-right"></i></a>

    </div>
</nav>