import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-admin-redirect',
    templateUrl: './admin-redirect.component.html',
    styleUrls: ['./admin-redirect.component.scss']
})
export class AdminRedirectComponent implements OnInit {

    constructor(
    ) {
        window.location.href = '/admin/';
    }
    ngOnInit() {
    }
}
