import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PermissionService } from './permission.service';
import { NotifierService } from './notifier.service';
import { AuthenticationService } from './authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable({
    providedIn: 'root'
})
export class GuardIsLoginService implements CanActivate {


    constructor(
        private _router: Router,
        private _notifierService: NotifierService,
        private _oauthService: OAuthService,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (!this._oauthService.hasValidAccessToken()) {
                resolve(false);
            } else {
                resolve(true);
            }
        });
    }
}
