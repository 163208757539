import { Observable, Observer, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

// see https://stackoverflow.com/a/12709880/1203690
declare global {
    interface Window {
        hubReady: Observer<boolean>;
    }
}

@Injectable()
export class MathService {
    private readonly notifier: ReplaySubject<boolean>;

    constructor() {
        console.log(`service constructor`);
        this.notifier = new ReplaySubject<boolean>();
        window.hubReady = this.notifier;
    }

    ready(): Observable<boolean> {
        return this.notifier;
    }

    render(element: HTMLElement, math?: string): void {
        // if (math) {
        //     if (math.latex) {
        //         element.innerText = math.latex;
        //     } else {
        //         element.innerHTML = math.mathml;
        //     }
        // }
        //console.log(element);
        element.innerHTML = math;
        //console.log(math);
        eval(`MathJax.Hub.Queue(['Typeset', MathJax.Hub, element])`);
    }
}