import { Pipe, PipeTransform } from '@angular/core';
import { BasicUserInfo } from '../models/basic-user-info';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'userFormat'
})
export class UserFormatPipe implements PipeTransform {


    constructor(
        private _userService: UserService
    ) {
    }

    async transform(value: number, args0?: string, args1?: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let result = '';

            if (value == null || !value || value < 0) {
                resolve(result);
            }

            const userInfo: BasicUserInfo = await this._userService.getBasicUserInfo(value);

            if (!args0 && !args1) {
                // Gia tri mac dinh, neu nhu khong truyen gi vao
                result = `${this.getUserInfo(userInfo, 'firstName')} ${this.getUserInfo(userInfo, 'lastName')}`;
            } else {
                if (args0) {
                    result = this.getUserInfo(userInfo, args0.valueOf());
                }

                if (args1) {
                    result += this.getUserInfo(userInfo, args1.valueOf());
                }
            }

            resolve(result);
        });
    }

    getUserInfo(userInfo: BasicUserInfo, format: string): string {
        if (!userInfo)
            return '';
        switch (format) {
            case 'NAME': {
                if (userInfo.firstName || userInfo.lastName) {
                    return `${userInfo.firstName} ${userInfo.lastName}`;
                } else {
                    return userInfo.fullName;
                }
            }
            case 'AVATAR': {
                if (userInfo.avatar) {
                    // Need format html
                    return userInfo.avatar;
                }
                return '';
            }

            default: {
                return userInfo[format];
            }
        }
    }
}
