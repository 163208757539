import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvComponentModule } from 'sv-shared';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule, DropdownModule } from 'primeng';
import { CoursesBoxsearchComponent } from './boxsearch/courses-boxsearch.component';
import { InteractiveVideoPlayerComponent } from './interactive-video-player/interactive-video-player.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MathModule } from '../math/math.module';

@NgModule({
    imports: [
        CommonModule,
        SvComponentModule,
        TranslateModule,
        DropdownModule,
        ReactiveFormsModule,
        DragDropModule,
        MathModule
    ],
    declarations: [
        CoursesBoxsearchComponent,
        InteractiveVideoPlayerComponent
    ],
    exports: [
        CoursesBoxsearchComponent,
        InteractiveVideoPlayerComponent
    ]
})
export class SvCommonModule { }
