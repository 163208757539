import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SvCorePublicService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/SvCorePublic`);
    }

    GetsByTrangThai(trangThai: number) {
        const queryString = `${this.serviceUri}/GetsByTrangThai/${trangThai}`;
        return this.defaultGet(queryString);
    }

    getsResourceByLanguage(lang: string) {
        const queryString = `${this.serviceUri}/GetsResourceByLanguage/${lang}`;
        return this.defaultGet(queryString);
    }

    resetPassword(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/resetPassword`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
}
