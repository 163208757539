<footer>
    <div class="elh-footer-widget-area">
        <div class="container">
            <div class="elh-footer-widgets">
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <aside class="widget">
                            <a class="footer-logo" routerLink="/">
                                <img src="assets/images/main/logo-2.png" alt="Site Logo">
                            </a>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mt-4"><b>Trường PTLC Edison</b>
                                    </div>
                                    <ul>
                                        <li>
                                            <i class="fa fa-home"></i> Phân khu Aquabay, Khu đô thị Ecopark
                                        </li>
                                        <li>
                                            <i class="fa fa-phone"></i> 096.880.8386
                                        </li>
                                        <li>
                                            <i class="fa fa-mobile"></i> 086 867 2386 (Máy lẻ: 1)
                                        </li>
                                        <li>
                                            <i class="fa fa-envelope"></i> tuyensinh@edisonschools.edu.vn
                                        </li>
                                        <li>
                                            <i class="fa fa-globe"></i> https://edisonschools.edu.vn/
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="elh-footer-lower-area">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <ul class="footer-menu footer-menu-left socials">
                        <li>
                            <a target="_blank" href="https://edisonschools.edu.vn/su-menh-tam-nhin-muc-tieu/">Giới
                                thiệu</a>
                        </li>
                        <li>
                            <a href="https://edisonschools.edu.vn/contact/">Liên hệ</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6 col-xs-12 text-right xs-text-center">
                    <div class="elh-footer-widget-area no-padding">
                        <div class="widget">
                            <ul class="footer-menu socials">
                                <li>© Edison 2020 all right reserved. </li>
                                <li>
                                    <a href="https://www.facebook.com/EdisonSchoolsEcopark/" target="_blank"><i
                                            class="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCEcROo-oprsMTdMejqPKMHA/featured"
                                        target="_blank"><i class="fa fa-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
