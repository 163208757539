import { DmTruongHocService } from './../../svmlearning/services/dm-truong-hoc.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-courses-boxsearch',
    templateUrl: './courses-boxsearch.component.html',
    styleUrls: ['./courses-boxsearch.component.scss']
})
export class CoursesBoxsearchComponent implements OnInit {

    dsTruongHoc = [];
    dsCategory = [
        { label: 'Tất cả', value: 0 }
        , { label: 'Chủ nhiệm', value: 101 }
        , { label: 'Chuyên môn', value: 102 }
        , { label: 'Kỹ năng', value: 103 }
        , { label: 'Công nghệ thông tin', value: 104 }
    ];
    idDmTruongHoc = 0;
    idCategory = 0;

    query = '';
    isChecked = false;
    @Output() goSearch = new EventEmitter<any>();

    constructor(
        private _route: ActivatedRoute,
        private _dmTruongHocService: DmTruongHocService
    ) { }

    async ngOnInit() {
        await this.getDs();
        this._route.queryParams.subscribe(params => {
            if (params['idDmTruongHoc'] !== undefined) {
                this.idDmTruongHoc = (params['idDmTruongHoc'] - 0);
                this.isChecked = true;
            }
            if (params['query'] !== undefined) {
                this.query = params['query'];
                this.isChecked = true;
            }
        });

        this._route.queryParams.subscribe(params => {
            if (params['idCategory'] !== undefined) {
                this.idCategory = (params['idCategory'] - 0);
                this.isChecked = true;
            }
        });
    }
    async getDs() {
        this.dsTruongHoc = [{ label: 'Tất cả', value: 0 }];
        await this._dmTruongHocService.getsAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.dsTruongHoc, rs.data, 'ten');
            }
        });
    }

    convertDataToOptions(options: any[], data: any[], fieldName: string) {
        if (data.length > 0) {
            data.map(item =>
                options.push({ label: item[fieldName], value: (item.id - 0) })
            );
        }
    }

    onGoSearch() {
        var data = {
            query: this.query,
            idDmTruongHoc: this.idDmTruongHoc,
            idCategory: this.idCategory,
        }
        this.goSearch.next(data);
    }
}