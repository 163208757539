import { InjectionToken, Optional, Inject, Injectable } from '@angular/core';
import { ModuleConfig } from '../configs/module-config';

export function moduleConfigFunc() {
    return new InjectionToken<ModuleConfig>('');
}

@Injectable({
    providedIn: 'root'
})
export class ModuleConfigService {
    protected _config: ModuleConfig;

    constructor(
        @Optional()
        @Inject(moduleConfigFunc)
        moduleConfigVal: any = null
    ) {
        this._config = moduleConfigVal() ||
            <ModuleConfig>{

            };
    }

    getConfig() {
        return this._config;
    }
}
