import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'courseitemicon'
})
export class CourseItemIconPipe implements PipeTransform {

    transform(idCourseItemType: number): string {
        if (idCourseItemType >= 10 && idCourseItemType <= 20) {
            return "fa fa-play-circle";
        } else if (idCourseItemType == 21) {
            return "fa fa-check-square-o";
        } else {
            return "fa fa-file-text-o";
        }

    }

}
