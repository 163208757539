import { Injectable, Injector } from '@angular/core';
import { ResponseResult } from '../models/response-result';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { MasterDataItem } from '../models/master-data-item';
import { SelectItem } from 'primeng/api';
import { CommonService } from './common.service';
import { catchError } from 'rxjs/operators';
import { SignalRService } from './signalr.service';
import { NotificationObjectType } from '../classes/constants';

@Injectable({
    providedIn: 'root'
})
export class MasterDataService {

    setupSignalr = false;
    readonly apiEndpoint = environment.apiDomain.coreEndPoint;
    readonly storage = localStorage;
    readonly categoryPrefix = 'cat_';
    readonly administrativeUnit = 'administrative_unit_';
    readonly administrativeUnitItem = 'administrative_item_unit_';

    constructor(
        private _httpClient: HttpClient,
        private _commonService: CommonService,
        private _injector: Injector,
        private _signalRService: SignalRService
    ) {
        // if (!this.setupSignalr) {
        //     this._signalRService.start(
        //         null,
        //         environment.signalrConfig.topic.categoryUpdated,
        //         this.refreshCategory.bind(this)
        //     );

        //     this.setupSignalr = true;
        // }
    }

    get(groupCode: string): Promise<MasterDataItem[]> {
        return new Promise(async (resolve, reject) => {
            let dataSource: MasterDataItem[] = [];
            if (!this.storage.getItem(`${this.categoryPrefix}${groupCode}`)) {
                const result = await this.doGetAll(groupCode);

                result.data.forEach(element => {
                    dataSource.push(
                        { id: element.id, code: element.code, name: element.name }
                    )
                });

                this.storage.setItem(`${this.categoryPrefix}${groupCode}`, JSON.stringify(dataSource));
            } else {
                dataSource = <MasterDataItem[]>JSON.parse(this.storage.getItem(`${this.categoryPrefix}${groupCode}`));
            }

            resolve(dataSource);
        });
    }

    getConfigByCode(code: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            let config = '';
            if (!this.storage.getItem(code)) {
                const result = await this.doGetConfigByCode(code);
                this.storage.setItem(code, JSON.stringify(result.data));

                config = <string>result.data;
            } else {
                config = <string>JSON.parse(this.storage.getItem(code));
            }

            resolve(config);
        });
    }

    getDropdown(groupCode: string): Promise<SelectItem[]> {
        return new Promise(async (resolve, reject) => {
            this.get(groupCode).then(source => {
                const op = source.map(x => <SelectItem>{ title: x.name, label: x.name, value: x.code });
                resolve(op);
            }, error => reject(<SelectItem[]>[]));
        });
    }

    getItems(groupCode: string): Promise<SelectItem[]> {
        return new Promise(async (resolve, reject) => {
            this.get(groupCode).then(source => {
                const op = source.map(x => <SelectItem>{ label: x.name, value: x.code });
                resolve(op);
            }, error => reject(<SelectItem[]>[]));
        });
    }

    getGroupByCode(groupCode: string): Promise<any[]> {
        return new Promise(async (resolve, reject) => {
            this.get(groupCode).then(source => {
                resolve(source);
            }, error => reject(<any[]>[]));
        });
    }

    getDropdownAdministrativeUnit(id): Promise<SelectItem[]> {
        return new Promise(async (resolve, reject) => {
            this.getAdministrativeUnit(id).then(source => {
                const op = source.map(x => <SelectItem>{ title: x.name, label: x.name, value: x.code });
                resolve(op);
            }, error => reject(<SelectItem[]>[]));
        });
    }

    getAdministrativeUnit(id): Promise<MasterDataItem[]> {
        return new Promise(async (resolve, reject) => {
            let dataSource: MasterDataItem[] = [];
            if (!this.storage.getItem(`${this.administrativeUnit}${id}`)) {
                const result = await this.doGetAdministrativeUnit(id);
                this.storage.setItem(`${this.administrativeUnit}${id}`, JSON.stringify(result.data));

                dataSource = <MasterDataItem[]>result.data;
            } else {
                dataSource = <MasterDataItem[]>JSON.parse(this.storage.getItem(`${this.administrativeUnit}${id}`));
            }

            resolve(dataSource);
        });
    }

    private doGetAdministrativeUnit(id: string): Promise<ResponseResult> {
        const serviceUri = `${this.apiEndpoint}/City/GetOrganizationsByParentId/${id}`;

        return this._httpClient.get<ResponseResult>(serviceUri)
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .toPromise();
    }
    getItemDetailUnit(id: string): Promise<MasterDataItem> {

        return new Promise(async (resolve, reject) => {
            let itemData: MasterDataItem = new MasterDataItem();
            if (!this.storage.getItem(`${this.administrativeUnitItem}${id}`)) {
                const serviceUri = `${this.apiEndpoint}/City/GetOrganizationByCode/${id}`;
                const result = await this._httpClient.get<ResponseResult>(serviceUri)
                    .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
                    .toPromise();

                this.storage.setItem(`${this.administrativeUnitItem}${id}`, JSON.stringify(result.data));

                itemData = <MasterDataItem>result.data;
            } else {
                itemData = <MasterDataItem>JSON.parse(this.storage.getItem(`${this.administrativeUnitItem}${id}`));
            }

            resolve(itemData);
        });
    }

    private doGetConfigByCode(code: string) {
        const serviceUri = `${this.apiEndpoint}/ConfigurationService/GetValueByCode?code=${code}`;

        return this._httpClient.get<ResponseResult>(serviceUri)
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .toPromise();
    }

    private doGetAll(groupCode: string, key?: string, limit?: number, offset?: number, sortField?: string, isAsc?: boolean): Promise<ResponseResult> {
        if (key == null) {
            key = '';
        }

        let serviceUri = `${this.apiEndpoint}/Single/GroupCode/${groupCode}?key=${key}`;
        let query = '';
        if (!limit) {
            limit = 300;
            query += `&limit=${limit}`;
        }

        if (!offset) {
            offset = 0;
            query += `&offset=${offset}`;
        }

        if (sortField) {
            query += `&sortField=${sortField}&isAsc=${isAsc}`;
        }

        serviceUri += query;
        return this._httpClient.get<ResponseResult>(serviceUri)
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .toPromise();
    }

    addWorkDays(date: string, days: number): Promise<ResponseResult> {
        const serviceUri = `${this.apiEndpoint}/Holiday/GetDate?date=${date}&&days=${days}`;
        return this._httpClient.get<ResponseResult>(serviceUri)
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .toPromise();
    }

    getItemByCode(dataSource: any, code: string): MasterDataItem {
        return dataSource.find(x => x.code === code);
    }

    getItemByInstanceId(dataSource: any, instanceId: string) {
        return dataSource.find(x => x.instanceId === instanceId);
    }

    getItemById(dataSource: any, id: number) {
        return dataSource.find(x => x.id === id);
    }

    getItemByGroupAndCode(groupCode: string, code: string): Promise<MasterDataItem> {
        return new Promise(async (resolve, reject) => {
            let dataSource: MasterDataItem[] = [];
            if (!this.storage.getItem(`${this.categoryPrefix}${groupCode}`)) {
                const result = await this.doGetAll(groupCode);
                console.log(JSON.stringify(result.data));
                this.storage.setItem(`${this.categoryPrefix}${groupCode}`, JSON.stringify(result.data));
                dataSource = <MasterDataItem[]>result.data;
            } else {
                dataSource = <MasterDataItem[]>JSON.parse(this.storage.getItem(`${this.categoryPrefix}${groupCode}`));
            }

            resolve(dataSource.find(x => x.code === code));
        });
    }

    refreshCategory() {
        console.log(this);
        for (const key in localStorage) {
            if (key.startsWith(this.categoryPrefix)) {
                this.storage.removeItem(key);
                const groupCode = key.split('_')[1];
                this.get(groupCode);
            }
        }
    }
    getAdress(city: any, district: any, ward: any, street: any, strText: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            let strResult = '';
            if (strText !== null) {
                strResult += strText + ', ';
            }
            if (street !== null) {
                await this.getItemDetailUnit(street).then(
                    x => {
                        if (x !== null) {
                            strResult += x.name + ', '
                        }
                    });
            }
            if (ward !== null) {
                await this.getItemDetailUnit(ward).then(
                    x => {
                        if (x !== null) {
                            strResult += x.name + ', '
                        }
                    });
            }
            if (district !== null) {
                await this.getItemDetailUnit(district).then(
                    x => {
                        if (x !== null) {
                            strResult += x.name + ', '
                        }
                    });
            }
            if (city !== null) {
                await this.getItemDetailUnit(city).then(
                    x => {
                        if (x !== null) {
                            strResult += x.name
                        }
                    });
            }
            resolve(strResult);
        });
    }

    getValueByCode(code: any): Promise<ResponseResult> {
        const url = `${this.apiEndpoint}/Single/getValueByCode?code=${code}`;
        return this._httpClient.get<ResponseResult>(url)
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .toPromise();
    }
}
