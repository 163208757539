import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { map } from 'rxjs/internal/operators/map';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable } from 'rxjs';
import { SvCorePublicService } from '../services/sv-core-public.service';

export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient,
        public resources: { prefix: string, suffix: string }[] = [{
            prefix: '/assets/i18n/',
            suffix: '.json'
        }]) { }

    public getTranslation(lang: string): any {
        return forkJoin(this.resources.map(config => {
            return this.http.get(`${config.prefix}${lang}${config.suffix}`);
        })).pipe(map(response => {
            return response.reduce((a, b) => {
                return Object.assign(a, b);
            });
        }));
    }

    // constructor(private http: HttpClient,
    //     private _svCorePublicService: SvCorePublicService
    // ) { }


    // public getTranslation(lang: string = 'vn'): any {
    //     return forkJoin(
    //         this._svCorePublicService.getsResourceByLanguage(lang).then(rs => {
    //             if (rs.success) {
    //                 localStorage.setItem(`language.${lang}`, JSON.stringify(rs.data));
    //                 return rs.data;
    //             }
    //         })
    //     ).pipe(map(response => {
    //         var result = response[0].reduce(function (map, obj) {
    //             map[obj.maResource] = obj.stringValue;
    //             return map;
    //         }, {});
    //         console.log(result);
    //         return result;
    //     }));
    // }
}

