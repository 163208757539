import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { HomeComponent } from './specific-page/home/home.component';
import { SigninComponent } from './specific-page/signin/signin.component';
import { GuardIsLoginService, GuardIsTeacherService } from 'sv-shared';
import { AdminRedirectComponent } from './specific-page/admin-redirect/admin-redirect.component';

export const routes: Routes = [
    {
        path: '',
        component: DefaultLayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'signin',
                component: SigninComponent
            },
            {
                // canActivate: [GuardIsLoginService],
                path: 'profile', loadChildren: './sv-profile/sv-profile.module#SvProfileModule'
            },
            {
                // canActivate: [GuardIsLoginService],
                path: 'study', loadChildren: './svmlearning/svstudent/svstudent.module#SvstudentModule'
            },
            {
                path: 'course', loadChildren: './svmlearning/courses/courses.module#CoursesModule'
            },
            {
                path: 'news', loadChildren: './svother/news/news.module#NewsModule'
            },
            {
                canActivate: [GuardIsLoginService],
                path: 'ilib', loadChildren: './svother/ilib/ilib.module#IlibModule'
            },
            {
                path: 'admin',
                component: AdminRedirectComponent
            }
        ],
    },
    {
        canActivate: [GuardIsLoginService],
        path: 'learning',
        component: LearningLayoutComponent,
        children: [
            {
                path: '', loadChildren: './svmlearning/learning/learning.module#LearningModule'
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'error/404'
    }
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy });
