import { CONFIG_CALENDAR_VIETNAMESE } from '../../../../../../src/app/config/calendar.config';
import { Output, EventEmitter, ViewChild, Injector, ElementRef, OnInit, HostListener } from '@angular/core';
import { ComponentBase } from './component-base';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormSchemaItem } from '../form-schema';
import { ValidationSummaryComponent } from '../../components/validation-summary/validation-summary.component';
import { NotifierService } from '../../services/notifier.service';
import { BaseService } from '../../services/base.service';
import { Dialog } from 'primeng/dialog';
import { CommonService } from '../../services/common.service';
import { SvUser } from '../../models/sv-user';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';


export class SecondPageEditBase extends ComponentBase implements OnInit {

    @ViewChild('validationSummary') validationSummary: ValidationSummaryComponent;
    @ViewChild('dialog') dialog: Dialog;
    @ViewChild('formElement') formElement: ElementRef;

    vi = CONFIG_CALENDAR_VIETNAMESE;
    isShow = false;
    submitting = false;
    continueAdding = true;
    invalid = false;
    itemDetail: any = {};
    formSchema: FormSchemaItem[] = [];
    uploadedFiles: any[] = [];

    popupHeader = '';
    popupId: string;
    formGroup: FormGroup = new FormGroup({});
    arrTrangThai = [
        { label: 'Sử dụng', value: 1 },
        { label: 'Không Sử dụng', value: 2 },
    ];

    currentUser: SvUser = new SvUser();
    protected _translateService: TranslateService;
    protected _notifierService: NotifierService;
    protected _authenticationService: AuthenticationService;
    openPopupDelete = false;

    @Output() closePopup = new EventEmitter<any>();

    constructor(
        protected _baseService: BaseService,
        protected _injector: Injector,

    ) {
        super();
        this._authenticationService = this._injector.get(AuthenticationService);
        this._notifierService = this._injector.get(NotifierService);
        this._translateService = this._injector.get(TranslateService);
        this.popupId = this._injector.get(CommonService).guid();
        this.currentUser = this._authenticationService.getCurrentUser();
    }

    ngOnInit(): void {
        // this._masterDataService.getConfigByCode('SizeOfFile')
        //     .then(response => {
        //         if (response) {
        //             this.limitSize = Number(response) * 1000 * 1000;
        //         }
        //     });
        this.continueAdding = true;
    }

    convertDataToOptions(options: any[], data: any[], fieldName: string) {
        if (data.length > 0) {
            data.map(item =>
                options.push({ label: item[fieldName], value: item.id })
            );
        }
    }
    saveAndClose() {
        this.continueAdding = false;
        this.save();
    }
    save() {
        this.submitting = true;
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            this.scrollToTop();
            return;
        }
        const rs1 = this.onBeforeSave();
        if ((typeof rs1 === 'boolean' && rs1) || typeof rs1 !== 'boolean') {
            if (this.itemDetail['id']) {
                this.onUpdate();
            } else {
                this.onInsert();
            }
        }
    }

    showValidateMessage() {
        this.validationSummary.showValidationSummary();
        this.submitting = false;
    }

    onBeforeSave(): boolean | void {
        return true;
    }

    onAfterSave() {

    }

    async showPopup(data = null) {
        this.isShow = true;
        this.continueAdding = true;
        await this.onShowPopup(data);
        setTimeout(() => {
            const viewRef = document.getElementById(this.popupId);
            if (viewRef != null) {
                const input = viewRef.querySelector('input');
                if (input) {
                    input.focus();
                } else {
                    const texateara = viewRef.querySelector('textarea');

                    if (texateara) {
                        texateara.focus();
                    }
                }
            }

            if (this.dialog) {
                //this.dialog.center();
            }
        }, 500);
    }

    onShowPopup(data: any) {

        this.onReset();

        this.onBeforeShowPopUp();

        if (data) {
            this._baseService.getDetail(data)
                .then(async response => {
                    this.itemDetail = response.data;
                    this.onAfterShowPopUp();
                }, () => {
                    this._notifierService.showHttpUnknowError();
                });
        }
    }

    onReset() {
        this.itemDetail = {};
    }

    onAfterShowPopUp() {

    }

    onBeforeShowPopUp() {

    }

    closePopupMethod(data: any) {
        this.isShow = false;
        this.closePopup.next(data);
    }

    getMaxWidthDialog() {
        return (window.innerHeight - 200).toString() + 'px';
    }

    onInsert() {
        this._baseService.post(this.itemDetail)
            .then(response => {
                this.itemDetail.id = response.data;
                if (this.continueAdding) {
                    this.resetForm();
                    this.itemDetail.id = 0;
                } else {
                    this.closePopupMethod(true);
                }
                this._notifierService.showInsertDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, () => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._baseService.put(this.itemDetail['id'].toString(), this.itemDetail)
            .then(() => {
                this.closePopupMethod(true);
                this._notifierService.showUpdateDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    cancel() {
        this.closePopupMethod(null);
    }

    buildFormGroup(formGroup?: FormGroup, formSchema?: FormSchemaItem[]) {
        const formGroupD = formGroup || this.formGroup;
        const formSchemaD = formSchema || this.formSchema;
        for (let i = 0; i < formSchemaD.length; i++) {
            const schemaItem = formSchemaD[i];
            const formControl = new FormControl('');
            if (schemaItem.control && schemaItem.control.defaultValue !== undefined) {
                this.itemDetail[schemaItem.field] = schemaItem.control.defaultValue;
                if (!schemaItem.visible && schemaItem.control.defaultValue) {
                    formControl.setValue(schemaItem.control.defaultValue);
                }
            }

            if (schemaItem.validators && schemaItem.validators.required) {
                formControl.setValidators(Validators.required);
            }

            formGroupD.addControl(schemaItem.field, formControl);
        }
    }

    resetForm() {
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        this.formGroup.reset();
        this.itemDetail = { id: 0 };
        this.uploadedFiles = [];
        this.submitting = false;
    }

    scrollToTop() {
        if (this.formElement) {
            setTimeout(() => {
                this.formElement.nativeElement.scrollIntoView();
            }, 500);
        }
    }

    onInitEditor(event: any) {
        const commonService = <CommonService>this._injector.get(CommonService);
        commonService.addTabDisabledHandler(event);
    }

    isValid() {
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            return;
        }

        return this.formGroup.valid;
    }

    delete() {
        this._notifierService.showDeleteConfirm().then(() => {
            this._baseService.delete(this.itemDetail.id)
                .then(() => {
                    this.closePopupMethod({});
                    this._notifierService.showDeleteDataSuccess();
                }, () => {
                    this._notifierService.showDeleteDataError();
                });
        });
    }

    togglePopupDelete() {
        this.openPopupDelete = !this.openPopupDelete;
    }

    getMaxDialogHeight() {
        return (window.innerHeight - 200).toString() + 'px';
    }

    @HostListener('window:keydown.alt.s', ['$event'])
    keyEventN(event: KeyboardEvent) {
        console.log(event);
        this.save();
    }
}
