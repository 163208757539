import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DmTruongHocService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/dmtruonghoc`);
    }
    getsByUser() {
        const apiUrl = `${this.serviceUri}/GetsByUser`
        return this.defaultGet(apiUrl);
    }
    getsAll() {
        const apiUrl = `${this.serviceUri}/GetsAll`
        return this.defaultGet(apiUrl);
    }
}
