import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'approval'
})
export class ApprovalPipe implements PipeTransform {

  transform(value: boolean, args?: any): any {
    if (value) {
      return '<i class=\'fa fa-check-square-o text-success\' aria-hidden=\'true\'></i>';
    }
    return '<i class=\'fa fa-square-o\' aria-hidden=\'true\'></i>';
  }

}
