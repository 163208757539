import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Carousel } from 'primeng';
import { CourseService } from '../../svmlearning/services/course.service';
import { DmNamhoc } from '../../svmlearning/models/dmnamhoc';
import { NotifierService } from 'sv-shared';
import { Router } from '@angular/router';
import { CustomerService } from '../../svother/services/customer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WishlistService } from '../../svother/services/wishlist.service';
import { FeedbackService } from '../../svother/services/feedback.service';
import { BannerService } from '../../svother/services/banner.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    @ViewChild('chuNhiem') chuNhiem: Carousel;
    @ViewChild('chuyenMon') chuyenMon: Carousel;
    @ViewChild('kyNang') kyNang: Carousel;
    @ViewChild('cntt') cntt: Carousel;
    @ViewChild('clBanner') clBanner: Carousel;
    slickImgConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
    }

    responsiveOptions = [];
    dsChuNhiem = [];
    dsKyNang = [];
    dsChuyenMon = [];
    dsCntt = [];
    dsBanner = [];
    dsCustomer = [];
    constructor(
        protected _courseService: CourseService,
        protected _customerService: CustomerService,
        protected _notifierService: NotifierService,
        protected _router: Router,
        protected _bannerService: BannerService,
    ) {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 3,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '579px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    async ngOnInit() {
        this.getData();
        this.getBanner();
        this.getDataDoiTac();
    }

    getBanner() {
        this.dsBanner = [];
        this._bannerService.get('', 1, 1, 99999, 'OrderNo', false).then(rs => {
            if (rs.success) {
                this.dsBanner = rs.data;
            }
        })
    }

    async getDataDoiTac() {
        var searchModel = {
            keyword: '',
            trangThai: 1,
            pageIndex: 1,
            pageSize: 9999,
            orderCol: 'Id',
            isDesc: false,
        };
        await this._customerService.getsBySearch(searchModel)
            .then(response => {
                this.dsCustomer = response.data;
                // response.data.forEach(item => {
                //     this.swiperCustomer.appendSlide([
                //         `<div class="swiper-slide swiper-slide-active" style="width: 190px; margin-right: 40px;"><a
                //         href="${item.linkUrl}" target="_blank"><img src="${item.imgUrl}"></a></div>`
                //     ]);
                // });
            });
        //this.swiperCustomer.update(true);

    }

    getData() {
        this._courseService.getRandomByCategory(0, 101, 12).then(rs => {
            if (rs.success) {
                this.dsChuNhiem = rs.data;
            }
        });
        this._courseService.getRandomByCategory(0, 102, 12).then(rs => {
            if (rs.success) {
                this.dsKyNang = rs.data;
            }
        });
        this._courseService.getRandomByCategory(0, 103, 12).then(rs => {
            if (rs.success) {
                this.dsChuyenMon = rs.data;
            }
        });
        this._courseService.getRandomByCategory(0, 104, 12).then(rs => {
            if (rs.success) {
                this.dsCntt = rs.data;
            }
        })
    }
    clBannerPrev() {
        this.clBanner.navBackward(null);
    }
    clBannerNext() {
        this.clBanner.navForward(null);
    }
    chuNhiemPrev() {
        this.chuNhiem.navBackward(null);
    }

    chuNhiemNext() {
        this.chuNhiem.navForward(null);
    }

    chuyenMonPrev() {
        this.chuyenMon.navBackward(null);
    }

    chuyenMonNext() {
        this.chuyenMon.navForward(null);
    }

    kyNangPrev() {
        this.kyNang.navBackward(null);
    }

    kyNangNext() {
        this.kyNang.navForward(null);
    }

    cnttPrev() {
        this.cntt.navBackward(null);
    }

    cnttNext() {
        this.cntt.navForward(null);
    }
    onGoSearch(data: any) {
        var url = `/course?act=search`;
        if (data.idDmTruongHoc > 0) {
            url += `&idDmTruongHoc=${data.idDmTruongHoc}`
        }
        if (data.idCategory > 0) {
            url += `&idCategory=${data.idCategory}`
        }
        if (data.query !== undefined && data.query !== '') {
            url += `&query=${data.query}`
        }
        this._router.navigateByUrl(url);
    }
}
