import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';

@Injectable({
    providedIn: 'root'
})
export class FsFoldersService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.storageEndPoint}/fsfolders`);
    }

    getsChildFolder(idFsFolderCha: number, idDmPhanTo: number, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsChildFolder/${idFsFolderCha}/${idDmPhanTo}/${trangThai}`;
        return this.defaultGet(url);
    }

    getsChildByIdFsFolder(idFsFolderCha: number, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsChildByIdFsFolder/${idFsFolderCha}/${trangThai}`;
        return this.defaultGet(url);
    }

    createFolder(parentFolderId: number, idDmPhanTo: number, ten: string): Promise<ResponseResult> {
        const url = `${this.serviceUri}/CreateFolder/${parentFolderId}/${idDmPhanTo}/${ten}`;
        return this._http
            .post<ResponseResult>(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getTree(idDmPhanTo: number, idFolderExclude: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTree/${idDmPhanTo}/${idFolderExclude}`;
        return this.defaultGet(url);
    }

    createMyFolder(parentFolderId: number, ten: string): Promise<ResponseResult> {
        const url = `${this.serviceUri}/CreateMyFolder?parentFolderId=${parentFolderId}&ten=${ten}`;
        return this._http
            .post<ResponseResult>(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    updateMyFolder(model: any) {
        const url = `${this.serviceUri}/UpdateMyFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsSharedToMe(): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsSharedToMe`;
        return this.defaultGet(url);
    }

    moveToOtherFolder(idFsFolder: number, model: any) {
        const url = `${this.serviceUri}/MoveToOtherFolder/${idFsFolder}`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsMyTrash(): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsMyTrash`;
        return this.defaultGet(url);
    }

    updateTrangThai(trangThai: number, model: any) {
        const url = `${this.serviceUri}/UpdateTrangThai/${trangThai}`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
