import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-learning-layout',
    templateUrl: './learning-layout.component.html',
    styleUrls: ['./learning-layout.component.scss']
})
export class LearningLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
