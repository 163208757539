<div class="container text-center">
    <input type="checkbox" name="" class="hidden" id="advanced-filter-check" [checked]="isChecked">
    <div class="search-group">
        <div class="row row-0">
            <div class="col-8 col-sm-6 col-sm-push-3">
                <input type="search" [(ngModel)]="query" (keyup.enter)="onGoSearch()">
            </div>
            <div class="col-4 col-sm-3 col-sm-push-3">
                <button type="submit" class="btn" (click)="onGoSearch()">
                    <i class="fa fa-search"></i>
                    Tìm kiếm</button>
            </div>
            <div class="col-12 col-sm-3 col-sm-pull-9">
                <label for="advanced-filter-check" class="advanced-filter">
                    <div class="advanced-filter-content">
                        Tìm kiếm nâng cao
                    </div>
                    <div class="col-auto">
                        <div class="check-icon"></div>
                    </div>
                </label>
            </div>
        </div>
        <div class="advanced-filter-group">
            <div class="row row-10">
                <div class="col-sm-6 col-6">
                    <p-dropdown class="__control-input sv-dropdown" [options]="dsTruongHoc" name="idDmTruongHoc"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="idDmTruongHoc" [filter]="true"
                        filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-sm-6 col-6">
                    <p-dropdown class="__control-input sv-dropdown" [options]="dsCategory"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="idCategory" [filter]="true"
                        filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>