import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';

@Injectable({
    providedIn: 'root'
})
export class FsFilesService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.storageEndPoint}/fsfiles`);
    }

    getsByFolderId(idFsFolder: number, idDmPhanTo: number, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByFolderId/${idFsFolder}/${idDmPhanTo}/${trangThai}`;
        return this.defaultGet(url);
    }

    getsByIdFsFolder(idFsFolder: number, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByIdFsFolder/${idFsFolder}/${trangThai}`;
        return this.defaultGet(url);
    }

    getUploadUrl() {
        return `${this.serviceUri}/UploadFiles`;
    }

    getAnonymousDownloadUrl(instalceId: string) {
        return `${environment.apiDomain.storageEndPoint}/${instalceId}`;
    }

    isSupportedViewOnline(fileName: string): boolean {
        // file không có đuổi -> loại
        if (fileName.lastIndexOf('.') === -1) {
            return false;
        }

        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);

        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return true;
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return true;
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return true;
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return true;
        }

        return false;
    }

    find(model: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsSharedToMe(): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsSharedToMe`;
        return this.defaultGet(url);
    }

    moveToOtherFolder(idFsFolder: number, model: any) {
        const url = `${this.serviceUri}/MoveToOtherFolder/${idFsFolder}`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    getsMyTrash(): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsMyTrash`;
        return this.defaultGet(url);
    }

    updateTrangThai(trangThai: number, model: any) {
        const url = `${this.serviceUri}/UpdateTrangThai/${trangThai}`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    unZip(idFsFile: number) {
        const url = `${this.serviceUri}/UnZip/${idFsFile}`;
        return this._http
            .post<ResponseResult>(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
