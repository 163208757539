import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

    transform(fileName: string): string {
        if (!fileName) {
            return '';
        }
        return fileName.split('/').pop();
    }

}
