import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';
import { FsItemModel } from '../models/fsitem';

@Injectable({
    providedIn: 'root'
})
export class FsSystemFoldersService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svFsSystemEndpoint}/fsfolder`);
    }

    getTree(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTree`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsChildFolder(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsChildFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    createMyFolder(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/CreateMyFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    updateMyFolder(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/UpdateMyFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    moveToMyFolder(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/MoveToMyFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    deleteFolder(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getTreeByUserId(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTreeByUserId`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsChildFolderByUserId(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsChildFolderByUserId`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
