<!-- Banner -->
<div class="elh-banner style-3" id="elh-banner">
    <p-carousel #clBanner [value]="dsBanner" [numVisible]="1" [numScroll]="1" [autoplayInterval]="20000"
        [circular]="true" class="owl-carousel">
        <ng-template let-banner pTemplate="item">
            <div class="elh-banner-item">
                <img class="image-entity" src="{{banner.imgUrl}}" style="opacity: 1;">
                <div class="banner-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <h3 class="elh-banner-title" style="font-size: 3.2rem !important;"
                                    *ngIf="banner.ten != null || banner.ten != undefined">
                                    {{banner.ten}}</h3>
                                <h4 class="elh-banner-subtitle"
                                    *ngIf="banner.noiDung != null || banner.noiDung != undefined">
                                    {{banner.noiDung}}</h4><br>
                                <div class="elh-button-group">
                                    <a class="btn" target="_blank" href="{{banner.linkUrl}}">
                                        Xem chi tiết
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-footer>
            <div class="owl-controls clickable">
                <div class="owl-buttons">
                    <div class="owl-prev" (click)="clBannerPrev()"><i class="fa fa-angle-left"></i></div>
                    <div class="owl-next" (click)="clBannerNext()"><i class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </p-footer>
    </p-carousel>
</div>
<!-- Banner End -->

<!-- Main wrapper start -->
<div class="elh-main-wrap">

    <!-- Registration -->
    <div class="elh-section-padding-big elh-bottom-0">
        <app-courses-boxsearch (goSearch)="onGoSearch($event)"></app-courses-boxsearch>
    </div>
    <!-- Registration End -->

    <!-- Popular Courses -->
    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section" *ngIf="dsChuNhiem.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Chủ <span>nhiệm</span></h3>
                        <p class="elh-section-text">Các khóa học chủ nhiệm</p>
                        <a class="all-link" [routerLink]="['/course']"
                            [queryParams]="{act: search, idCategory: 101}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #chuNhiem [value]="dsChuNhiem" [numVisible]="3" [numScroll]="1"
                    [responsiveOptions]="responsiveOptions" [circular]="true"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? course.imageUrl : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.maDmTruongHoc}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countCourseUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countCourseComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="chuNhiemPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="chuNhiemNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section pop-section--block"
        *ngIf="dsChuyenMon.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Chuyên <span>môn</span></h3>
                        <p class="elh-section-text">Các khóa học nâng cao chuyên môn</p>
                        <a class="all-link" [routerLink]="['/course']"
                            [queryParams]="{act: search, idCategory: 102}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #chuyenMon [value]="dsChuyenMon" [numVisible]="3" [numScroll]="1"
                    [responsiveOptions]="responsiveOptions" [circular]="true"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? course.imageUrl : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.maDmTruongHoc}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countCourseUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countCourseComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="chuyenMonPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="chuyenMonNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section" *ngIf="dsKyNang.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Kỹ <span></span></h3>
                        <p class="elh-section-text">Các khóa học Kỹ năng</p>
                        <a class="all-link" [routerLink]="['/course']"
                            [queryParams]="{act: search, idCategory: 103}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #kyNang [value]="dsKyNang" [numVisible]="3" [numScroll]="1"
                    [responsiveOptions]="responsiveOptions" [circular]="true"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? course.imageUrl : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.maDmTruongHoc}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countCourseUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countCourseComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="kyNangPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="kyNangNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>

    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section" *ngIf="dsCntt.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Kỹ <span></span></h3>
                        <p class="elh-section-text">Các khóa học Kỹ năng</p>
                        <a class="all-link" [routerLink]="['/course']"
                            [queryParams]="{act: search, idCategory: 104}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #cntt [value]="dsCntt" [numVisible]="3" [numScroll]="1"
                    [responsiveOptions]="responsiveOptions" [circular]="true"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? course.imageUrl : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.maDmTruongHoc}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countCourseUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countCourseComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="cnttPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="cnttNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>
    <!-- Popular Courses End -->



    <!-- Our instructor -->
    <!-- Our instructor end -->

    <!-- Partner Logoes -->
    <div class="elh-section elh-section-padding-big">
        <div class="elh-section-header text-center">
            <h3 class="elh-section-title"><span>Đối tác</span></h3>
            <p class="elh-section-text">Chúng tôi phối hợp với những đối tác hàng đầu trong lĩnh vực giáo dục</p>
        </div>
        <div class="container">
            <div class="partner-lgs text-center">
                <a target="_blank" class="partner-lg" href="{{item.linkUrl}}" *ngFor="let item of dsCustomer">
                    <img src="{{item.imgUrl}}" alt="{{item.ma}}" title="{{item.ma}}">
                </a>
            </div>
        </div>
    </div>
    <!-- Partner Logoes End -->
</div>
<!-- Main wrapper start end -->
