import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from 'sv-shared';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private auth: AuthenticationService, private router: Router) {
    }

    canActivate() {
        if (!this.auth.isLoggedIn()) {
            this.router.navigateByUrl('/tai-khoan/dang-nhap');
            return false;
        }
        return true;
    }
}
