import {
    Component,
    Input,
    EventEmitter,
    Output,
    ChangeDetectionStrategy
} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sv-pagination',
    templateUrl: './pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./pagination.component.scss']
})
export class SvPaginationComponent {
    @Input() pageIndex: number;
    @Input() totalRecord: number;
    @Input() pageSize: number;
    @Input() loading: boolean;
    @Input() pagesToShow: number;

    @Output() onPrePage = new EventEmitter<boolean>();
    @Output() onNextPage = new EventEmitter<boolean>();
    @Output() onGoPage = new EventEmitter<number>();

    constructor() { }

    getMin(): number {
        return this.pageSize * this.pageIndex - this.pageSize + 1;
    }

    getMax(): number {
        let max = this.pageSize * this.pageIndex;
        if (max > this.totalRecord) {
            max = this.totalRecord;
        }
        return max;
    }
    onPageMax(): void {
        const totalPage =
            this.totalRecord % this.pageSize > 0
                ? this.totalRecord / this.pageSize + 1
                : this.totalRecord / this.pageSize;
        this.onGoPage.emit(totalPage);
    }
    onPageMin(n: number): void {
        this.onGoPage.emit(1);
    }
    onPage(n: number): void {
        this.onGoPage.emit(n);
    }

    onPrev(): void {
        this.onPrePage.emit(true);
    }

    onNext(next: boolean): void {
        this.onNextPage.emit(next);
    }

    totalPages(): number {
        return Math.ceil(this.totalRecord / this.pageSize) || 0;
    }

    lastPage(): boolean {
        return this.pageSize * this.pageIndex >= this.totalRecord;
    }

    getPages(): number[] {
        const c = Math.ceil(this.totalRecord / this.pageSize);
        const p = this.pageIndex || 1;
        const pagesToShow = this.pagesToShow || 9;
        const pages: number[] = [];
        pages.push(p);
        const times = pagesToShow - 1;
        for (let i = 0; i < times; i++) {
            if (pages.length < pagesToShow) {
                if (Math.min.apply(null, pages) > 1) {
                    pages.push(Math.min.apply(null, pages) - 1);
                }
            }
            if (pages.length < pagesToShow) {
                if (Math.max.apply(null, pages) < c) {
                    pages.push(Math.max.apply(null, pages) + 1);
                }
            }
        }
        pages.sort((a, b) => a - b);
        return pages;
    }
}
