<div class="elh-page-header elh-img-bg elh-bg-17">
    <div class="elh-overlay elh-overlay-custom">
        <div class="container text-center">
            <h3 class="elh-page-title">HỆ THỐNG ĐÀO TẠO NỘI BỘ</h3>
            <ol class="breadcrumb">
                <li class="active">Edison</li>
            </ol>
        </div>
    </div>
</div>


<div class="elh-section elh-section-padding bg">
    <div class="container">
        <div class="row" *ngIf="showLogin">
            <div class="col-sm-4 col-sm-offset-4">
                <form #formElement [formGroup]="formGroup" class="instructor-registration text-center">
                    <h4>ĐĂNG NHẬP</h4>
                    <div class="ui-g row" *ngIf="formGroup.invalid">
                        <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                        </validation-summary>
                    </div>
                    <p><input type="text" name="email" placeholder="Tên đăng nhập" required="true"
                            [(ngModel)]="model.username" formControlName="username"></p>
                    <p><input type="password" name="password" placeholder="Mật khẩu" required="true"
                            formControlName="password" [(ngModel)]="model.password" type="password"
                            (keyup.enter)="doLogin()"></p>
                    <p>
                        <label><input type="checkbox" name="toc-check" required=""> Duy trì đăng nhập</label>
                        <label><a (click)="showLogin = !showLogin">Quên mật khẩu</a></label>
                    </p>
                    <a (click)="doLogin()" class="btn">Đăng nhập</a>
                </form>
            </div>
        </div>
        <div class="row" *ngIf="!showLogin">
            <div class="col-sm-4 col-sm-offset-4">
                <form id="instructorRegistration" class="instructor-registration text-center">
                    <h4>Quên mật khẩu</h4>
                    <p><input type="text" name="email" placeholder="Tên đăng nhập" required="true"
                            [(ngModel)]="model.username"></p>
                    <p><input type="text" name="email" placeholder="Email" required="" [(ngModel)]="model.email"
                            (keyup.enter)="doResetPassword()"></p>
                    <p>
                        <label><a (click)="showLogin = !showLogin">Trở về</a></label>
                    </p>
                    <a (click)="doResetPassword()" class="btn">Quên mật khẩu</a>
                </form>
            </div>
        </div>
    </div>
</div>
