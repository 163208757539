import { TreeNode } from 'primeng/api';
export class FsItemModel {

    ten: string;
    physicalPath: string;
    totalSize: number;
    idFsItemType: number;
    tenFsItemType: string;
    physicalPathParent: string;
    webPath: string;
    rootPath: string;
    isClick: boolean;
    countShared: number;
    idOrganization: number;
    userId: number;
}

export class ExTreeNode {
    label?: string;
    data?: any;
    icon?: any;
    expandedIcon?: any;
    collapsedIcon?: any;
    children?: ExTreeNode[];
    leaf?: boolean;
    expanded?: boolean;
    type?: string;
    parent?: ExTreeNode;
    partialSelected?: boolean;
    styleClass?: string;
    draggable?: boolean;
    droppable?: boolean;
    selectable?: boolean;
    key?: string;
    parentData?: string;

}