import { environment } from './../../../../../../../src/environments/environment';
import { FsItemModel } from './../../../models/fsitem';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Dialog } from 'primeng';
declare var DocsAPI: any;

@Component({
    selector: 'app-fssystem-file-viewer',
    templateUrl: './fssystem-file-viewer.component.html',
    styleUrls: ['./fssystem-file-viewer.component.scss']
})
export class FssystemFileViewerComponent implements OnInit {
    fsItem = new FsItemModel();
    fileUrl = '';
    @Output() closePopup = new EventEmitter<any>();
    @ViewChild('dialog') dialog: Dialog;
    isShow = false;
    isImage = false;
    isMaximized = false;
    constructor(
    ) { }

    ngOnInit() {
    }

    initDocumentViewer(fileType, documentType, fileUrl, fileName) {
        console.log(fileUrl);
        const config = {
            'height': '100%',
            'width': '100%',
            'documentType': `${documentType}`,
            'editorConfig': {
                'mode': 'view',
                'customization': {
                    'chat': false,
                    'compactToolbar': true,
                    // 'logo': {
                    //     'image': `${logoUrl}`,
                    //     'imageEmbedded': `${logoUrl}`
                    // },
                    'about': false,
                    'comments': false
                }
            },
            'document': {
                'permissions': {
                    'comment': false,
                    'download': true,
                    'edit': false,
                    'print': true,
                    'review': false,
                    'reader': true
                },
                'fileType': `${fileType}`,
                'title': `${fileName}`,
                'url': `${fileUrl}`
            }
        };
        const docEditor = new DocsAPI.DocEditor('file-viewer', config);
    }

    getFileType(fileName: string) {
        const arr = fileName.split('.');
        return arr[arr.length - 1];
    }

    getDocumentType(fileType: string) {
        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return 'image';
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return 'text';
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return 'spreadsheet';
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return 'presentation';
        }

        return '';
    }

    showPopup(status: boolean, data: any = {}) {
        this.isShow = status;
        this.fsItem = data;
        const fileType = this.getFileType(this.fsItem.ten);
        const documentType = this.getDocumentType(fileType);
        this.fileUrl = `${environment.apiDomain.svFsSystemEndpoint}${this.fsItem.webPath}`;
        console.log(this.fileUrl);
        if (documentType === 'image') {
            this.isImage = true;
            console.log('aaa');
        } else {
            this.isImage = false;
            setTimeout(() => {
                this.dialog.maximize();
                var view = document.getElementById("fileViewContent").parentElement.style;
                view.height = "calc(100% - 50px)";
                view.margin = "-15px";
                this.initDocumentViewer(fileType, documentType, this.fileUrl, data.ten);
            }, 500);
        }
    }
}
